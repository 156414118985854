
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import PrizesProvider from '@context/prizesContext';
import StagesProvider from '@context/stagesContext';
import { ThemeNames } from '@context/themeContext';
import { useTheme } from '@hooks/useTheme';
import Layout from '@layout';
import LandingPageLayout from '@layout/LandingPage';
import Default from '@modules/LandingPages/Home';
import { useRouter } from 'next/router';
import { ReactElement, useEffect } from 'react';
export default HomePage;
HomePage.getLayout = function getLayout(page: ReactElement) {
    return (<StagesProvider>
      <Layout>
        <LandingPageLayout background='linear-gradient(180deg, #004E26 0%, #00A651 13.54%, #8BC96C 55.73%)'>{page}</LandingPageLayout>
      </Layout>
    </StagesProvider>);
};
function HomePage() {
    const router = useRouter();
    const { setTheme } = useTheme();
    useEffect(() => {
        setTheme(ThemeNames.default);
    }, []);
    useEffect(() => {
        if (router.query != null && router.query.redirectUrl) {
            let redirectUrl = String(router.query.redirectUrl);
            if (redirectUrl != undefined &&
                redirectUrl != '' &&
                redirectUrl != null) {
                router.push(Buffer.from(redirectUrl, 'base64').toString('ascii'));
            }
        }
    }, []);
    return (<PrizesProvider>
      <Default />
    </PrizesProvider>);
}

    async function __Next_Translate__getStaticProps__1929acf6a22__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1929acf6a22__ as getStaticProps }
  